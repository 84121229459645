import React from 'react';

import SEO from '../components/seo';
import StoreContent from '../components/store-content';

import { prepareHandle } from '../utils/uri-utils';

const StoreBrand = ({ pageContext: { brand }, data }) => (
	<>
		<SEO
			title={brand}
			path={`/store/brand/${prepareHandle(brand)}/`}
			description={`Browse our selection of ${brand} jewelry pieces available for purchase. Become a member to get 25% off our store!`}
		/>
		<StoreContent
			title={brand}
			items={data.allShopifyProduct.edges}
			includeViewAll={true}
		/>
	</>
);

export const query = graphql`
    query($vendor: String!) {
        allShopifyProduct(
            filter: {
                vendor: { eq: $vendor }
            }
            sort: {
            	fields: [title]
            	order: [ASC]
            }
        ) {
            edges {
                node {
                    id
                    title
                    handle
                    productType
                    vendor
                    shopifyId
                    createdAt
                    images {
                        id
                        originalSrc
                        localFile {
                            childImageSharp {
                                fluid(quality: 90, maxWidth: 680) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                    variants {
                        shopifyId
                        price
                    }
                }
            }
        }
    }
`;

export default StoreBrand;
